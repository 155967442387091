import { SecondTeam, UserAnswer, UserState } from "../types/userTypes";

interface TestResult {
  gaiko: number;
  kaiho: number;
  seijitu: number;
  chyowa: number;
}

export const calcTestScore = (answerArray: UserAnswer[]): TestResult => {
  let gaiko = 0;
  let kaiho = 0;
  let seijitu = 0;
  let chyowa = 0;

  answerArray.forEach((answerData) => {
    const { answer, index } = answerData;

    if (index === 1 || index === 2 || index === 7) {
      gaiko += answer;
    }

    if (index === 3 || index === 4 || index === 6) {
      kaiho += answer;
    }

    if (index === 9 || index === 10 || index === 12) {
      seijitu += answer;
    }

    if (index === 5 || index === 8 || index === 11) {
      chyowa += answer;
    }
  });

  gaiko = (gaiko * 10) / 3;
  kaiho = (kaiho * 10) / 3;
  seijitu = (seijitu * 10) / 3;
  chyowa = (chyowa * 10) / 3;

  return { gaiko, kaiho, seijitu, chyowa };
};

export const setSecondTeamList = (userArray: UserState[]): SecondTeam => {
  const member: string[] = [];
  const men: string[] = [];
  const women: string[] = [];

  userArray.forEach((user) => {
    const { sex, name } = user;
    member.push(name);

    const manCount = userArray.filter((user) => user.sex === "男").length;

    const womanCount = userArray.filter((user) => user.sex === "女").length;

    // console.log(manCount, womanCount, men, women);

    // 男女差が2以上の時は、性別を無視して配列の長さを揃える
    if (Math.abs(manCount - womanCount) < 2) {
      if (sex === "男") men.push(name);
      if (sex === "女") women.push(name);
    } else {
      if (manCount > womanCount) {
        if (sex === "女") women.push(name);

        if (men.length > womanCount && sex === "男") {
          women.push(name);
        } else if (sex === "男") {
          men.push(name);
        }
      }

      if (womanCount > manCount) {
        if (sex === "男") men.push(name);
        if (women.length > manCount && sex === "女") {
          men.push(name);
        } else if (sex === "女") {
          women.push(name);
        }
      }
    }
  });
  const riddles = member.length > 5 ? 9 : 6;

  return { member, men, women, riddles };
};

export const dengerousName = ["畠山枝里子", "輔田泰智", "長谷川耀"];
