import styles from "./TextWithSpinner.module.scss";

interface Props {
  text?: string;
  isLoading: boolean;
}

const TextWithSpinner: React.FC<Props> = ({ text = "", isLoading }) => {
  return (
    <>
      <div className="relative min-h-[16px]">
        <div className={isLoading ? "opacity-60" : ""}>{text}</div>
        {isLoading && (
          <div className="absolute top-1 w-full">
            <div
              className={`${styles.loader} mx-auto h-4 w-4 rounded-full border-2 border-t-2 ease-linear`}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default TextWithSpinner;
