import { SecondTeamResult, Setting } from "./commonTypes";
import { SecondTeam, UserAnsweredSurvey } from "./userTypes";

export const isSettingState = (value: unknown): value is Setting => {
  return !!(value as Setting)?.eventId;
};

export const isUserAnsweredSurvey = (
  value: unknown
): value is UserAnsweredSurvey => {
  return !!(value as UserAnsweredSurvey)?.surveyName;
};

export const isSecondTeam = (value: unknown): value is SecondTeam => {
  return !!(
    (value as SecondTeam)?.men.length > 0 &&
    (value as SecondTeam)?.women.length > 0
  );
};

export const isSecondTeamResult = (
  value: unknown
): value is SecondTeamResult => {
  return !!(value as SecondTeamResult)?.teamName;
};

export const isString = (value: unknown): value is string => {
  return typeof value === "string";
};

export const isStringArray = (value: unknown): value is string[] => {
  return Array.isArray(value) && value.every((val) => typeof val === "string");
};
