import { collection, doc, onSnapshot, query, where } from "firebase/firestore";
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { converter, firestore } from "../lib/firebase";
import { useUserAuth } from "./userAuth.context";
import * as Sentry from "@sentry/nextjs";
import { SecondTeam, UserState } from "../types/userTypes";
import { toast } from "react-hot-toast";
import { setSecondTeamList } from "../lib/helper";
import { SecondTeamResult } from "../types/commonTypes";

interface ContextType {
  userState: UserState | null;
  secondTeam: SecondTeam | null;
  secondTeamResult: SecondTeamResult | null;
  setSheetUid: Dispatch<SetStateAction<string>>;
}

interface Props {
  children: ReactNode;
}

const UserStateContext = createContext<ContextType | null>(null);

export const UserStateProvider: React.FC<Props> = ({ children }) => {
  const { uid } = useUserAuth();

  const [sheetUid, setSheetUid] = useState<string>("");
  const [userState, setUserState] = useState<UserState | null>(null);
  const [secondTeam, setSecondTeam] = useState<SecondTeam | null>(null);
  const [secondTeamResult, setSecondTeamResult] =
    useState<SecondTeamResult | null>(null);

  const { secondTeamIndex, eventId } = userState || {};

  useEffect(() => {
    let unsub: VoidFunction;
    if (uid && sheetUid) {
      const ref = doc(firestore, `users/${sheetUid}`).withConverter(
        converter<UserState>()
      );

      unsub = onSnapshot(ref, (snap) => {
        if (snap.exists()) {
          console.log("UserState", snap.data());
          Sentry.setUser({ uid: sheetUid });
          setUserState(snap.data());
        } else {
          toast.error("IDが間違っているようです。");
          setSheetUid("");
        }
      });
    }

    return () => {
      if (unsub) return unsub();
    };
  }, [uid, sheetUid]);

  useEffect(() => {
    let unsub: VoidFunction;
    if (userState && secondTeamIndex) {
      const userQuery = query(
        collection(firestore, "users"),
        where("secondTeamIndex", "==", secondTeamIndex),
        where("eventId", "==", eventId)
      ).withConverter(converter<UserState>());

      unsub = onSnapshot(userQuery, (snap) => {
        const users = snap.docs
          .map((data) => data.data())
          .sort((first, second) => {
            if (first.userIndex > second.userIndex) return 1;
            return -1;
          });
        // .filter((data) => {
        //   const { sheetUid: dataUid, psychologicalTest } = data;

        //   if (dataUid !== sheetUid && psychologicalTest.length > 0)
        //     return data;
        // });

        const team = setSecondTeamList(users);
        console.log("secondTeam", team);

        setSecondTeam(team);
      });
    }

    return () => {
      if (unsub) return unsub();
    };
  }, [eventId, secondTeamIndex, sheetUid, userState]);

  useEffect(() => {
    let unsub: VoidFunction;
    if (userState && secondTeamIndex) {
      const ref = doc(
        firestore,
        `courses/${eventId}/secondTeams/${secondTeamIndex}`
      ).withConverter(converter<SecondTeamResult>());

      unsub = onSnapshot(ref, (snap) => {
        if (snap.exists()) {
          console.log("SecondTeamResult", snap.data());
          setSecondTeamResult(snap.data());
        }
      });
    }

    return () => {
      if (unsub) return unsub();
    };
  }, [eventId, secondTeamIndex, userState]);

  const value = useMemo(
    () => ({ userState, secondTeam, secondTeamResult, setSheetUid }),

    [secondTeam, userState, secondTeamResult]
  );

  return (
    <UserStateContext.Provider value={value}>
      {children}
    </UserStateContext.Provider>
  );
};

export const useUserState = () => useContext(UserStateContext) as ContextType;
