import { onAuthStateChanged, signInAnonymously } from "firebase/auth";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { auth } from "../lib/firebase";
import * as Sentry from "@sentry/nextjs";
import { useRouter } from "next/router";
import { isString } from "../types/typeGuard";
import TextWithSpinner from "../../components/shared/TextWithSpinner";

interface ContextType {
  uid: string;
}

interface Props {
  children: ReactNode;
}

const UserAuthContext = createContext<ContextType | null>(null);

export const UserAuthProvider: React.FC<Props> = ({ children }) => {
  const [uid, setUid] = useState<string>("");
  const router = useRouter();

  const {
    query: { eventId },
  } = router;

  useEffect(() => {
    const authUnsub = onAuthStateChanged(auth, async (authObj) => {
      if (authObj) {
        console.log(authObj);
        setUid(authObj.uid);
      } else {
        try {
          if (!isString(eventId)) return;

          await signInAnonymously(auth);
        } catch (error) {
          Sentry.captureException(error);
        }
      }
    });

    return () => {
      authUnsub();
    };
  }, [eventId]);

  if (!uid)
    return (
      <div className="mt-9 h-full w-full items-center justify-center bg-slate-500 p-9">
        <p className="mb-3 text-center font-bold text-white">読み込み中...</p>
        <TextWithSpinner isLoading />
        <p className="mt-6 text-center text-sm text-white">
          しばらく経っても画面が切り替わらない場合、読み込みに失敗している可能性があります。
          <br />
          恐れ入りますが画面の再読み込みをお願いいたします。
        </p>
      </div>
    );

  return (
    <UserAuthContext.Provider value={{ uid }}>
      {children}
    </UserAuthContext.Provider>
  );
};

export const useUserAuth = () => useContext(UserAuthContext) as ContextType;
