import "../common/styles/globals.scss";
import "../common/styles/animations.scss";
import "../common/styles/override.scss";
import type { AppPropsWithLayout } from "next/app";
import { RecoilRoot } from "recoil";
import { UserAuthProvider } from "../common/context/userAuth.context";
import { UserStateProvider } from "../common/context/userState.context";
import { SettingStateProvider } from "../common/context/settingState.context";
import { Toaster } from "react-hot-toast";

const MyApp = ({ Component, pageProps }: AppPropsWithLayout) => {
  const getLayout = Component.getLayout ?? ((page) => page);

  return (
    <UserAuthProvider>
      <SettingStateProvider>
        <UserStateProvider>
          {/* <MainFlameTemplate> */}
          <RecoilRoot>
            <Toaster />
            {getLayout(<Component {...pageProps} />)}
          </RecoilRoot>
          {/* </MainFlameTemplate> */}
        </UserStateProvider>
      </SettingStateProvider>
    </UserAuthProvider>
  );
};

export default MyApp;
