import { doc, onSnapshot } from "firebase/firestore";
import { useRouter } from "next/router";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { converter, firestore } from "../lib/firebase";
import { Setting } from "../types/commonTypes";
import { useUserAuth } from "./userAuth.context";

interface SettingContext {
  settingState: Setting | null;
}

interface Props {
  children: ReactNode;
}

const SettingStateContext = createContext<SettingContext | null>(null);

export const SettingStateProvider: React.FC<Props> = ({ children }) => {
  const { uid } = useUserAuth();

  const [settingState, setSettingState] = useState<Setting | null>(null);
  const router = useRouter();

  const {
    query: { eventId },
  } = router;

  // 設定データの取得
  useEffect(() => {
    let unsub: VoidFunction;

    if (uid) {
      const ref = doc(firestore, `courses/${eventId}`).withConverter(
        converter<Setting>()
      );

      unsub = onSnapshot(ref, (snap) => {
        if (snap.exists()) {
          console.log("Setting", snap.data());
          setSettingState(snap.data());
        }
      });
    }

    return () => {
      if (unsub) return unsub();
    };
  }, [eventId, uid]);

  return (
    <SettingStateContext.Provider
      value={{
        settingState,
      }}
    >
      {children}
    </SettingStateContext.Provider>
  );
};

export const useSettingState = () =>
  useContext(SettingStateContext) as SettingContext;
